/* eslint-disable */
// File generated by i18n-js
import { I18n } from "i18n-js";
const i18n = new I18n({
  "en": {
    "activerecord": {
      "attributes": {
        "conversation": {
          "id": "ID",
          "installed_user": "Remplacé",
          "replacement": "Remplacement",
          "substitute_user": "Remplaçant"
        },
        "equipment": {
          "categories": {
            "furniture": "Mobilier",
            "medical_devices": "Appareils médicaux",
            "supplies": "Fournitures"
          },
          "category": "Catégorie",
          "id": "ID",
          "name": "Nom"
        },
        "locum_tenens_license": {
          "delivered_at": "Date de délivrance",
          "file": "Fichier",
          "id": "ID",
          "number": "Numéro de licence",
          "status": "Status",
          "statuses": {
            "approved": "Approuvée",
            "pending": "En attente",
            "rejected": "Rejetée"
          }
        },
        "message": {
          "attachements": "Pièces-jointes",
          "content": "Contenu",
          "id": "ID",
          "sender": "Expéditeur"
        },
        "office": {
          "accomodation_comments": "Précisions sur le logement",
          "additional_information": "Complément d'adresse",
          "address": "Adresse",
          "city": "Ville",
          "comments": "Précisions",
          "country": "Pays",
          "credit_card_reader": "TPE (lecteur de CB)",
          "doctors_comments": "Précisions sur les médecins",
          "examination_rooms": "Nombre de salles d'examen",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "name": "Nom",
          "pictures": "Photos",
          "retrocession_comments": "Précisions sur la rétrocession",
          "retrocession_delay": "Délai de rétrocession",
          "retrocession_percentage": "Pourcentage de rétrocession",
          "software": "Logiciel utilisé",
          "street": "Adresse",
          "street_information": "Informations sur l'adresse",
          "user_id": "Utilisateur",
          "vitale_reader": "Lecteur de carte Vitale",
          "waiting_room_comments": "Précisions sur la salle d'attente",
          "zipcode": "Code postal"
        },
        "professional_card": {
          "delivered_at": "Date de délivrance",
          "file": "Fichier",
          "id": "ID",
          "number": "Numéro",
          "status": "Status",
          "statuses": {
            "approved": "Approuvée",
            "pending": "En attente",
            "rejected": "Rejetée"
          }
        },
        "replacement": {
          "comment": "Précisions",
          "ends_on": "Date de fin",
          "id": "ID",
          "office": "Cabinet",
          "schedule_ids": "Plannings",
          "schedules": "Plannings",
          "starts_on": "Date de début",
          "status": "Statut",
          "statuses": {
            "expired": "Expired",
            "finished": "Terminé",
            "online": "En ligne",
            "provided": "Pourvu"
          },
          "substitute": "Médecin remplaçant",
          "substitute_id": "Médecin remplaçant",
          "user": "Médecin"
        },
        "replacement_search": {
          "fingerprint": "Emprunte",
          "id": "ID",
          "last_result_id": "Dernier résultat",
          "params": "Paramètres",
          "user": "Médecin"
        },
        "schedule": {
          "comment": "Commentaire",
          "id": "ID",
          "name": "Nom",
          "office": "Cabinet",
          "schedule_type": "Type de planning",
          "schedule_types": {
            "doctor": "Médecin",
            "secretary": "Secrétariat"
          }
        },
        "schedule_slot": {
          "id": "ID",
          "name": "Nom",
          "slot_type": "Type de créneau",
          "slot_types": {
            "consultations": "Consultations libres",
            "establishment_visits": "Visite en établissement",
            "home_visits": "Visites à domicile",
            "no_secretary": "Aucun secrétariat",
            "oncall": "Garde",
            "online_secretary": "Secrétariat en ligne",
            "onsite_secretary": "Secrétariat physique",
            "other": "Autre",
            "phone_secretary": "Secrétariat téléphonique",
            "scheduled_consultations": "Consultations sur rendez-vous",
            "via_platforms": "Via plateformes (Doctolib, etc.)"
          }
        },
        "user": {
          "email": "Email",
          "first_name": "Prénom",
          "gender": "Genre",
          "genders": {
            "female": "Femme",
            "male": "Homme"
          },
          "last_name": "Nom de famille",
          "password": "Mot de passe",
          "password_confirmation": "Confirmation du mot de passe",
          "phone": "Téléphone",
          "rpps": "RPPS",
          "user_types": {
            "admin": "Admin",
            "installed": "Installé",
            "substitute": "Remplaçant"
          }
        },
        "visit": {
          "created_at": "Date de création",
          "id": "ID",
          "updated_at": "Date de modification",
          "user": "Utilisateur",
          "visitable": "Visitable",
          "visitable_id": "ID de visitable",
          "visitable_type": "Type de visitable"
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        }
      },
      "models": {
        "conversation": "Conversation",
        "equipment": "Équipement",
        "locum_tenens_license": "Licence de remplacement",
        "message": "Message",
        "office": "Cabinet",
        "replacement": "Remplacement",
        "replacement_search": "Recherche de remplacement",
        "schedule": "Planning",
        "schedule_slot": "Créneau",
        "user": "Utilisateur",
        "visit": "Visite"
      }
    },
    "admin": {
      "dashboard": {
        "index": {
          "dashboard": "🏔️ Mont Olympes",
          "installed_registration_barcode": "Code pour médecin remplacé",
          "installed_registration_link": "Lien pour médecin remplacé",
          "substitute_registration_barcode": "Code pour médecin remplaçant",
          "substitute_registration_link": "Lien pour médecin remplaçant",
          "welcome": "Bienvenue %{user} ! 👋"
        }
      }
    },
    "back": "Retour",
    "controllers": {
      "application": {
        "user_not_authorized": "Vous n'êtes pas autorisé à faire cette action."
      },
      "installed": {
        "offices": {
          "create": {
            "notice": "Cabinet créé ! 🎉"
          },
          "destroy": {
            "notice": "Cabinet supprimé."
          },
          "update": {
            "notice": "Cabinet modifié."
          }
        },
        "profiles": {
          "update": {
            "notice": "Profil modifié."
          },
          "update_email": {
            "alert": "Votre adresse email n'a pas pu être modifiée. %{errors}.",
            "notice": "Adresse email modifiée."
          }
        },
        "replacements": {
          "cancel": {
            "notice": "Votre remplacement a été annulé."
          },
          "create": {
            "notice": "Remplacement créé et mis en ligne."
          },
          "provide": {
            "alert": "Votre remplacement n'a pas pu être indiqué comme pourvu.",
            "notice": "Votre remplacement a été indiqué comme pourvu."
          },
          "update": {
            "notice": "Remplacement mis à jour."
          }
        },
        "schedules": {
          "create": {
            "notice": "Planning créé ! 🎉"
          },
          "destroy": {
            "notice": "Planning supprimé."
          },
          "update": {
            "notice": "Planning modifié."
          }
        }
      },
      "substitute": {
        "profiles": {
          "update": {
            "notice": "Profil modifié."
          },
          "update_email": {
            "notice": "Adresse email modifiée."
          }
        },
        "replacement_searches": {
          "create": {
            "notice": "🔔 Vous serez notifié(e) des remplacements correspondant à vos critères."
          },
          "destroy": {
            "notice": "🔕 Vous ne recevrez plus de notifications pour ces critères."
          }
        },
        "replacements": {
          "cancel": {
            "notice": "Votre remplacement a été annulé."
          }
        }
      }
    },
    "conversation_mailer": {
      "new_message": {
        "body": "Vous avez reçu un nouveau message de %{user} concernant votre remplacement %{replacement}.",
        "closing": "A bientôt,",
        "greeting": "Bonjour %{user},",
        "reply": "Répondre : ",
        "subject": "[Alorempla] Nouveau message de %{user}"
      }
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about %{count} hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about %{count} month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about %{count} year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost %{count} year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than %{count} second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over %{count} year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "%{count} day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "%{count} second",
          "other": "%{count} seconds"
        },
        "x_years": {
          "one": "%{count} year",
          "other": "%{count} years"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Second",
        "year": "Year"
      }
    },
    "days": "Jours",
    "delete": "Supprimer",
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_confirmation_info": "Envoyer les instructions",
          "title": "Compte non confirmé ?"
        },
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirmer mon email",
          "greeting": "Bienvenue %{recipient} !",
          "instruction": "Vous pouvez confirmer votre email grâce au lien ci-dessous :",
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous informer que votre email a été changé en %{email}.",
          "message_unconfirmed": "Nous vous contactons pour vous informer que votre email est en train d'être changé par %{email}.",
          "subject": "Email Changed"
        },
        "password_change": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous notifier que votre mot de passe a été modifié.",
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "action": "Changer mon mot de passe",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Quelqu'un a demandé un lien pour changer votre mot de passe, le voici :",
          "instruction_2": "Si vous n'avez pas émis cette demande, merci d'ignorer cet email.",
          "instruction_3": "Votre mot de passe ne changera pas tant que vous n'aurez pas cliqué sur ce lien et renseigné un nouveau mot de passe.",
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "action": "Débloquer mon compte",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Suivez ce lien pour débloquer votre compte :",
          "message": "Votre compte a été bloqué suite à un nombre d'essais de connexions manquées trop important.",
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Modifier mon mot de passe",
          "confirm_new_password": "Confirmer le nouveau mot de passe",
          "minimum_password_length": "Minimum de %{count} caractères",
          "new_password": "Nouveau mot de passe",
          "or_continue_with": "ou continuer avec",
          "title": "Modifier mon mot de passe"
        },
        "new": {
          "or_continue_with": "ou continuer avec",
          "send_password_reset_info": "Envoyer les instructions",
          "title": "Mot de passe oublié ?"
        },
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "new": {
          "already_a_member": "Déjà inscrit ?",
          "minimum_password_length": "Minimum de %{count} caractères",
          "or_continue_with": "ou continuer avec",
          "sign_up": "Inscription",
          "signin_to_your_account": "Connectez vous.",
          "title": "Inscription en tant que %{as}"
        },
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "new": {
          "not_a_member": "Pas encore inscrit ?",
          "or": "ou",
          "or_continue_with": "ou continuer avec",
          "remember_me": "Rester connecté",
          "sign_in": "Connexion",
          "signup_as_installed": "Inscrivez-vous comme installé",
          "signup_as_substitute": "Inscrivez-vous comme remplaçant",
          "title": "Connexion"
        },
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "shared": {
        "links": {
          "didnt_receive_confirm_info": "Informations de confirmation non reçues ?",
          "didnt_receive_unlock_info": "Informations de déverrouillage non reçues ?",
          "forgot_password": "Mot de passe oublié ?",
          "signin": "Connexion",
          "signup": "Inscription"
        }
      },
      "unlocks": {
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_unlock_instructions": "Envoyer les instructions",
          "title": "Compte verrouillé ?"
        },
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "doctor": "Médecin",
    "edit": "Modifier",
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "already_confirmed": "was already confirmed, please try signing in",
        "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
        "aspect_ratio_not_landscape": "must be a landscape image",
        "aspect_ratio_not_portrait": "must be a portrait image",
        "aspect_ratio_not_square": "must be a square image",
        "aspect_ratio_unknown": "has an unknown aspect ratio",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "content_type_invalid": "has an invalid content type",
        "dimension_height_equal_to": "height must be equal to %{length} pixel",
        "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
        "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
        "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
        "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
        "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
        "dimension_width_equal_to": "width must be equal to %{length} pixel",
        "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
        "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
        "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "file_size_not_between": "file size must be between %{min_size} and %{max_size} (current size is %{file_size})",
        "file_size_not_greater_than": "file size must be greater than %{min_size} (current size is %{file_size})",
        "file_size_not_greater_than_or_equal_to": "file size must be greater than or equal to %{min_size} (current size is %{file_size})",
        "file_size_not_less_than": "file size must be less than %{max_size} (current size is %{file_size})",
        "file_size_not_less_than_or_equal_to": "file size must be less than or equal to %{max_size} (current size is %{file_size})",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "image_metadata_missing": "is not a valid image",
        "image_not_processable": "is not a valid image",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "limit_out_of_range": "total number is out of range",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is %{count} character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is %{count} character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "total_file_size_not_between": "total file size must be between %{min_size} and %{max_size} (current size is %{total_file_size})",
        "total_file_size_not_greater_than": "total file size must be greater than %{min_size} (current size is %{total_file_size})",
        "total_file_size_not_greater_than_or_equal_to": "total file size must be greater than or equal to %{min_size} (current size is %{total_file_size})",
        "total_file_size_not_less_than": "total file size must be less than %{max_size} (current size is %{total_file_size})",
        "total_file_size_not_less_than_or_equal_to": "total file size must be less than or equal to %{max_size} (current size is %{total_file_size})",
        "url": "is not a valid URL",
        "wrong_length": {
          "one": "is the wrong length (should be %{count} character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "template": {
        "body": "There were problems with the following fields:",
        "header": {
          "one": "%{count} error prohibited this %{model} from being saved",
          "other": "%{count} errors prohibited this %{model} from being saved"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      }
    },
    "hello": "Hello world",
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "home": {
      "index": {
        "go_to_admin_dashboard": "Accéder à mon compte admin",
        "go_to_installed_dashboard": "Accéder à mon compte installé",
        "go_to_substitute_dashboard": "Accéder à mon compte remplaçant",
        "installed_doctor": "Médecin installé à la recherche d'un remplaçant",
        "sign_in": "Connexion",
        "sign_up_as_installed": "S'inscrire comme installé",
        "sign_up_as_substitute": "S'inscrire comme remplaçant",
        "substitute_doctor": "Médecin remplaçant à la recherche d'un cabinet"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "À": "A",
          "Â": "A",
          "Æ": "Ae",
          "Ç": "C",
          "È": "E",
          "É": "E",
          "Ê": "E",
          "Ë": "E",
          "Î": "I",
          "Ï": "I",
          "Ô": "O",
          "Ù": "U",
          "Û": "U",
          "Ü": "U",
          "à": "a",
          "â": "a",
          "æ": "ae",
          "ç": "c",
          "è": "e",
          "é": "e",
          "ê": "e",
          "ë": "e",
          "î": "i",
          "ï": "i",
          "ô": "o",
          "ù": "u",
          "û": "u",
          "ü": "u",
          "ÿ": "y",
          "Œ": "Oe",
          "œ": "oe",
          "Ÿ": "Y"
        }
      }
    },
    "installed": {
      "conversations": {
        "conversation": {
          "last_message": "Dernier message il y a",
          "show_conversation": "Voir",
          "unread_messages": {
            "one": "Un message non lu",
            "other": "%{count} messages non lu",
            "zero": "Aucun message non lu"
          }
        },
        "index": {
          "my_conversations": "Mes conversations"
        },
        "show": {
          "about_replacement": "A propos de ce remplacement",
          "conversation": "Conversation avec le Dr %{substitute_user}",
          "conversations": "Conversations",
          "no_messages": "Aucun message",
          "show_replacement": "Voir le remplacement"
        }
      },
      "dashboard": {
        "index": {
          "create_office": "Créer mon cabinet",
          "create_replacement": "Créer mon remplacement",
          "create_your_first_office": "Créez votre premier cabinet afin de pouvoir publier votre recherche de remplaçants.",
          "create_your_first_replacement": "Créez votre première demande de remplacement.",
          "dashboard": "Accueil médecin remplacé",
          "next_step_office": "🏥 Prochaine étape",
          "next_step_replacement": "📅 Prochaine étape",
          "welcome": "Bienvenue %{user} ! 👋"
        }
      },
      "messages": {
        "form": {
          "write_your_message": "Rédigez votre message"
        },
        "message": {
          "read": "Lu"
        }
      },
      "offices": {
        "edit": {
          "edit_office": "Modifier le cabinet %{office}"
        },
        "form": {
          "comments": "Précisions",
          "equipments": "Équipements",
          "information": "Informations",
          "save_office": "Sauvegarder le cabinet",
          "settings": "Paramètres"
        },
        "index": {
          "my_offices": "Mes cabinets",
          "new_office": "Nouveau cabinet"
        },
        "new": {
          "new_office": "Nouveau cabinet"
        },
        "office": {
          "show_office": "Voir le cabinet",
          "updated": "Dernière mise à jour il y a"
        },
        "show": {
          "add_schedule": "Nouveau planning",
          "address": "Adresse",
          "days": "jours",
          "equipments": "Équipements",
          "information": "Informations",
          "no_pictures": "Aucune image",
          "office": "Cabinet %{office}",
          "pictures": "Photos",
          "schedules": "Plannings"
        }
      },
      "profiles": {
        "edit": {
          "edit_profile": "Modifier mon profil"
        },
        "form": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "save_profile": "Sauvegarder mon profil"
        },
        "show": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "profile": "Mon profil"
        }
      },
      "replacements": {
        "edit": {
          "edit_replacement": "Modifier le remplacement %{replacement}"
        },
        "form": {
          "date_range": "Plage de remplacement",
          "information": "Informations",
          "save_replacement": "Sauvegarder le remplacement",
          "select_schedules": "Sélectionner un ou des plannings"
        },
        "index": {
          "my_replacements": "Mes remplacements",
          "new_replacement": "Nouveau remplacement"
        },
        "new": {
          "new_replacement_for_office": "Nouveau remplacement pour %{office}"
        },
        "prepare_form": {
          "continue": "Continuer",
          "office": "Cabinet"
        },
        "prepare_new": {
          "new_replacement": "Nouveau remplacement"
        },
        "show": {
          "cancel_replacement": "Annuler le remplacement",
          "cancel_replacement_confirmation": "Voulez-vous vraiment annuler le remplacement ?",
          "conversation": "Conversation",
          "delete_confirmation": "Voulez-vous vraiment supprimer ce remplacement ?",
          "found_someone": "Vous avez trouvé un remplaçant ?",
          "information": "Informations",
          "mark_as_provided": "Marquer comme pourvu",
          "replacement": "Remplacement %{replacement}",
          "replacement_provided": {
            "female": "Remplaçante trouvée ! 🎉",
            "male": "Remplaçant trouvé ! 🎉"
          },
          "select_substitute": "Sélectionnez le médecin remplaçant"
        }
      },
      "schedules": {
        "form": {
          "information": "Informations",
          "save_schedule": "Sauvegarder le planning",
          "schedule": "Planning"
        },
        "new": {
          "new_schedule_for_office": "Nouveau planning pour le cabinet %{office}"
        },
        "show": {
          "create_slot": "Nouveau créneau",
          "delete": "Supprimer",
          "delete_or_edit_slot": "Modifier ou supprimer un créneau.",
          "new_schedule_slot_name": "Nom du créneau",
          "save": "Sauvegarder",
          "schedule": "Planning",
          "schedule_for_office": "Planning %{schedule} pour le cabinet %{office}",
          "slot_title": "Nom du créneau",
          "slot_title_cannot_be_empty": "Le nom du créneau est requis.",
          "slot_type": "Type de créneau"
        }
      }
    },
    "layouts": {
      "admin": {
        "dashboard": "Accueil",
        "notifications": "Notifications",
        "sign_out": "Déconnexion"
      },
      "installed": {
        "conversations": "Conversations",
        "dashboard": "Accueil",
        "notifications": "Notifications",
        "offices": "Cabinets",
        "replacements": "Remplacements",
        "sign_out": "Déconnexion"
      },
      "substitute": {
        "conversations": "Conversations",
        "dashboard": "Accueil",
        "notifications": "Notifications",
        "replacements": "Remplacements",
        "sign_out": "Déconnexion"
      }
    },
    "missing": "Manquant(e)",
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "open": "Ouvrir",
    "replacement_mailer": {
      "new_search_results": {
        "address": "Autour de %{address}",
        "body": "De nouveaux remplacements correspondent à votre recherche créée le %{date}.",
        "ends_on": "Jusqu'au %{date}",
        "equipments": "Équipements disponibles obligatoirement : %{equipments}",
        "greeting": "Bonjour %{user},",
        "radius": "Dans un rayon de %{radius} km",
        "search_params": "Pour rappel, vos paramètres de recherche sont :",
        "starts_on": "Début à partir du %{date}",
        "stop_notifications": "Pour ne plus recevoir de notifications pour cette recherche, cliquez sur le lien ci-dessous.",
        "subject": "[Alorempla] Nouveaux remplacements correspondant à votre recherche"
      }
    },
    "required": "requis",
    "shared": {
      "flashes": {
        "a_problem_occurred": "Un problème est survenu  👎",
        "attention": "Attention  👀",
        "well_done": "Bien joué  👍"
      },
      "update_email_panel": {
        "change_email_address_from_psc": "Vous vous êtes inscrit(e) avec Pro Santé Connect. Par conséquent, nous ne disposons pas de votre adresse e-mail personnelle pour vous envoyer des notifications.",
        "confirm_new_email_address": "Confirmez votre nouvelle adresse e-mail en cliquant sur le lien que nous vous avons envoyé.",
        "email": "Adresse email",
        "email_address_update_pending": "📨 Mise à jour de l'adresse e-mail en cours",
        "save": "Enregistrer",
        "update_email_address": "Mettez à jour votre adresse e-mail !"
      }
    },
    "show": "Afficher",
    "substitute": {
      "conversations": {
        "conversation": {
          "last_message": "Dernier message il y a",
          "show_conversation": "Voir",
          "unread_messages": {
            "one": "Un message non lu",
            "other": "%{count} messages non lu",
            "zero": "Aucun message non lu"
          }
        },
        "index": {
          "my_conversations": "Mes conversations"
        },
        "show": {
          "about_replacement": "A propos de ce remplacement",
          "conversation": "Conversation avec le Dr %{installed_user}",
          "conversations": "Conversations",
          "no_messages": "Aucun message",
          "show_replacement": "Voir le remplacement"
        }
      },
      "dashboard": {
        "index": {
          "dashboard": "Accueil médecin remplaçant",
          "find_your_first_replacement": "Trouvez votre premièr remplacement.",
          "next_step_replacement": "📅 Prochaine étape",
          "search_replacement": "Rechercher",
          "welcome": "Bienvenue %{user} ! 👋"
        }
      },
      "messages": {
        "form": {
          "write_your_message": "Rédigez votre message"
        },
        "message": {
          "read": "Lu"
        }
      },
      "profiles": {
        "edit": {
          "edit_profile": "Modifier mon profil"
        },
        "form": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "save_profile": "Sauvegarder mon profil"
        },
        "show": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "profile": "Mon profil"
        }
      },
      "replacements": {
        "index": {
          "my_replacements": "Mes remplacements",
          "search_replacement": "Chercher un remplacement"
        },
        "search": {
          "address": "Adresse",
          "delete_notifications_for_new_results": "Supprimer les notifications",
          "ends_on": "Fin avant le...",
          "equipments": "Équipements du cabinet",
          "filters": "Filtres",
          "get_notified_of_new_results": "Recevoir les nouveaux résultats",
          "in_radius": "Dans un rayon de (km)",
          "no_replacements_for_your_criterias": "Aucun remplacement ne correspond à vos critères",
          "radius": "Rayon (km)",
          "search": "Rechercher",
          "search_around": "Chercher autour de",
          "search_around_this_address": "Chercher autour de cette adresse",
          "search_replacement": "Chercher un remplacement",
          "starts_on": "Début aprés le..."
        },
        "search_replacement": {
          "contact": "Contacter",
          "dates": "Dates",
          "details": "Détails"
        },
        "show": {
          "cancel_replacement": "Annuler mon remplacement",
          "cancel_replacement_confirmation": "Voulez-vous vraiment annuler votre remplacement ? Le médecin en sera notifié.",
          "contact": "Contacter",
          "conversation": "Conversation",
          "equipments": "Équipements",
          "information": "Informations",
          "no_pictures": "Aucune image",
          "office": "Cabinet",
          "office_pictures": "Photos du cabinet",
          "replacement": "Remplacement %{replacement}",
          "schedules": "Plannings",
          "you_are_the_substitute_for_this_replacement": {
            "female": "Vous êtes la médecin remplaçante ! 🎉",
            "male": "Vous êtes le médecin remplaçant ! 🎉"
          }
        }
      }
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M",
        "time": "%H:%M"
      },
      "pm": "pm"
    }
  },
  "fr": {
    "activerecord": {
      "attributes": {
        "conversation": {
          "id": "ID",
          "installed_user": "Remplacé",
          "replacement": "Remplacement",
          "substitute_user": "Remplaçant"
        },
        "equipment": {
          "categories": {
            "furniture": "Mobilier",
            "medical_devices": "Appareils médicaux",
            "supplies": "Fournitures"
          },
          "category": "Catégorie",
          "id": "ID",
          "name": "Nom"
        },
        "locum_tenens_license": {
          "delivered_at": "Date de délivrance",
          "file": "Fichier",
          "id": "ID",
          "number": "Numéro de licence",
          "status": "Status",
          "statuses": {
            "approved": "Approuvée",
            "pending": "En attente",
            "rejected": "Rejetée"
          }
        },
        "message": {
          "attachements": "Pièces-jointes",
          "content": "Contenu",
          "id": "ID",
          "sender": "Expéditeur"
        },
        "office": {
          "accomodation_comments": "Précisions sur le logement",
          "additional_information": "Complément d'adresse",
          "address": "Adresse",
          "city": "Ville",
          "comments": "Précisions",
          "country": "Pays",
          "credit_card_reader": "TPE (lecteur de CB)",
          "doctors_comments": "Précisions sur les médecins",
          "examination_rooms": "Nombre de salles d'examen",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "name": "Nom",
          "pictures": "Photos",
          "retrocession_comments": "Précisions sur la rétrocession",
          "retrocession_delay": "Délai de rétrocession",
          "retrocession_percentage": "Pourcentage de rétrocession",
          "software": "Logiciel utilisé",
          "street": "Adresse",
          "street_information": "Informations sur l'adresse",
          "user_id": "Utilisateur",
          "vitale_reader": "Lecteur de carte Vitale",
          "waiting_room_comments": "Précisions sur la salle d'attente",
          "zipcode": "Code postal"
        },
        "professional_card": {
          "delivered_at": "Date de délivrance",
          "file": "Fichier",
          "id": "ID",
          "number": "Numéro",
          "status": "Status",
          "statuses": {
            "approved": "Approuvée",
            "pending": "En attente",
            "rejected": "Rejetée"
          }
        },
        "replacement": {
          "comment": "Précisions",
          "ends_on": "Date de fin",
          "id": "ID",
          "office": "Cabinet",
          "schedule_ids": "Plannings",
          "schedules": "Plannings",
          "starts_on": "Date de début",
          "status": "Statut",
          "statuses": {
            "expired": "Expired",
            "finished": "Terminé",
            "online": "En ligne",
            "provided": "Pourvu"
          },
          "substitute": "Médecin remplaçant",
          "substitute_id": "Médecin remplaçant",
          "user": "Médecin"
        },
        "replacement_search": {
          "fingerprint": "Emprunte",
          "id": "ID",
          "last_result_id": "Dernier résultat",
          "params": "Paramètres",
          "user": "Médecin"
        },
        "schedule": {
          "comment": "Commentaire",
          "id": "ID",
          "name": "Nom",
          "office": "Cabinet",
          "schedule_type": "Type de planning",
          "schedule_types": {
            "doctor": "Médecin",
            "secretary": "Secrétariat"
          }
        },
        "schedule_slot": {
          "id": "ID",
          "name": "Nom",
          "slot_type": "Type de créneau",
          "slot_types": {
            "consultations": "Consultations libres",
            "establishment_visits": "Visite en établissement",
            "home_visits": "Visites à domicile",
            "no_secretary": "Aucun secrétariat",
            "oncall": "Garde",
            "online_secretary": "Secrétariat en ligne",
            "onsite_secretary": "Secrétariat physique",
            "other": "Autre",
            "phone_secretary": "Secrétariat téléphonique",
            "scheduled_consultations": "Consultations sur rendez-vous",
            "via_platforms": "Via plateformes (Doctolib, etc.)"
          }
        },
        "user": {
          "email": "Email",
          "first_name": "Prénom",
          "gender": "Genre",
          "genders": {
            "female": "Femme",
            "male": "Homme"
          },
          "last_name": "Nom de famille",
          "password": "Mot de passe",
          "password_confirmation": "Confirmation du mot de passe",
          "phone": "Téléphone",
          "rpps": "RPPS",
          "user_types": {
            "admin": "Admin",
            "installed": "Installé",
            "substitute": "Remplaçant"
          }
        },
        "visit": {
          "created_at": "Date de création",
          "id": "ID",
          "updated_at": "Date de modification",
          "user": "Utilisateur",
          "visitable": "Visitable",
          "visitable_id": "ID de visitable",
          "visitable_type": "Type de visitable"
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "La validation a échoué : %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Vous ne pouvez pas supprimer l'enregistrement parce que les %{record} dépendants existent",
            "has_one": "Vous ne pouvez pas supprimer l'enregistrement car un(e) %{record} dépendant(e) existe"
          }
        }
      },
      "models": {
        "conversation": "Conversation",
        "equipment": "Équipement",
        "locum_tenens_license": "Licence de remplacement",
        "message": "Message",
        "office": "Cabinet",
        "replacement": "Remplacement",
        "replacement_search": "Recherche de remplacement",
        "schedule": "Planning",
        "schedule_slot": "Créneau",
        "user": "Utilisateur",
        "visit": "Visite"
      }
    },
    "admin": {
      "dashboard": {
        "index": {
          "dashboard": "🏔️ Mont Olympes",
          "installed_registration_barcode": "Code pour médecin remplacé",
          "installed_registration_link": "Lien pour médecin remplacé",
          "substitute_registration_barcode": "Code pour médecin remplaçant",
          "substitute_registration_link": "Lien pour médecin remplaçant",
          "welcome": "Bienvenue %{user} ! 👋"
        }
      }
    },
    "back": "Retour",
    "controllers": {
      "application": {
        "user_not_authorized": "Vous n'êtes pas autorisé à faire cette action."
      },
      "installed": {
        "offices": {
          "create": {
            "notice": "Cabinet créé ! 🎉"
          },
          "destroy": {
            "notice": "Cabinet supprimé."
          },
          "update": {
            "notice": "Cabinet modifié."
          }
        },
        "profiles": {
          "update": {
            "notice": "Profil modifié."
          },
          "update_email": {
            "alert": "Votre adresse email n'a pas pu être modifiée. %{errors}.",
            "notice": "Adresse email modifiée."
          }
        },
        "replacements": {
          "cancel": {
            "notice": "Votre remplacement a été annulé."
          },
          "create": {
            "notice": "Remplacement créé et mis en ligne."
          },
          "provide": {
            "alert": "Votre remplacement n'a pas pu être indiqué comme pourvu.",
            "notice": "Votre remplacement a été indiqué comme pourvu."
          },
          "update": {
            "notice": "Remplacement mis à jour."
          }
        },
        "schedules": {
          "create": {
            "notice": "Planning créé ! 🎉"
          },
          "destroy": {
            "notice": "Planning supprimé."
          },
          "update": {
            "notice": "Planning modifié."
          }
        }
      },
      "substitute": {
        "profiles": {
          "update": {
            "notice": "Profil modifié."
          },
          "update_email": {
            "notice": "Adresse email modifiée."
          }
        },
        "replacement_searches": {
          "create": {
            "notice": "🔔 Vous serez notifié(e) des remplacements correspondant à vos critères."
          },
          "destroy": {
            "notice": "🔕 Vous ne recevrez plus de notifications pour ces critères."
          }
        },
        "replacements": {
          "cancel": {
            "notice": "Votre remplacement a été annulé."
          }
        }
      }
    },
    "conversation_mailer": {
      "new_message": {
        "body": "Vous avez reçu un nouveau message de %{user} concernant votre remplacement %{replacement}.",
        "closing": "A bientôt,",
        "greeting": "Bonjour %{user},",
        "reply": "Répondre : ",
        "subject": "[Alorempla] Nouveau message de %{user}"
      }
    },
    "date": {
      "abbr_day_names": [
        "dim",
        "lun",
        "mar",
        "mer",
        "jeu",
        "ven",
        "sam"
      ],
      "abbr_month_names": [
        null,
        "jan.",
        "fév.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept.",
        "oct.",
        "nov.",
        "déc."
      ],
      "day_names": [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%-d %B %Y",
        "short": "%-d %b"
      },
      "month_names": [
        null,
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "environ une heure",
          "other": "environ %{count} heures"
        },
        "about_x_months": {
          "one": "environ un mois",
          "other": "environ %{count} mois"
        },
        "about_x_years": {
          "one": "environ un an",
          "other": "environ %{count} ans"
        },
        "almost_x_years": {
          "one": "presque un an",
          "other": "presque %{count} ans"
        },
        "half_a_minute": "une demi‑minute",
        "less_than_x_minutes": {
          "one": "moins d'une minute",
          "other": "moins de %{count} minutes",
          "zero": "moins d'une minute"
        },
        "less_than_x_seconds": {
          "one": "moins d'une seconde",
          "other": "moins de %{count} secondes",
          "zero": "moins d'une seconde"
        },
        "over_x_years": {
          "one": "plus d'un an",
          "other": "plus de %{count} ans"
        },
        "x_days": {
          "one": "%{count} jour",
          "other": "%{count} jours"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} mois",
          "other": "%{count} mois"
        },
        "x_seconds": {
          "one": "%{count} seconde",
          "other": "%{count} secondes"
        },
        "x_years": {
          "one": "%{count} an",
          "other": "%{count} ans"
        }
      },
      "prompts": {
        "day": "Jour",
        "hour": "Heure",
        "minute": "Minute",
        "month": "Mois",
        "second": "Seconde",
        "year": "Année"
      }
    },
    "days": "Jours",
    "delete": "Supprimer",
    "devise": {
      "confirmations": {
        "confirmed": "Votre compte a été validé.",
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_confirmation_info": "Envoyer les instructions",
          "title": "Compte non confirmé ?"
        },
        "send_instructions": "Vous allez recevoir les instructions nécessaires à la confirmation de votre compte dans quelques minutes.",
        "send_paranoid_instructions": "Si votre e-mail existe dans notre base de données, vous allez bientôt recevoir un e-mail contenant les instructions de confirmation de votre compte."
      },
      "failure": {
        "already_authenticated": "Vous êtes déjà connecté.",
        "inactive": "Votre compte n'est pas encore activé.",
        "invalid": "Email et/ou mot de passe incorrect(s).",
        "last_attempt": "Vous avez droit à une tentative avant que votre compte ne soit verrouillé.",
        "locked": "Votre compte est verrouillé.",
        "not_found_in_database": "Email et/ou mot de passe incorrect(s).",
        "timeout": "Votre session est expirée. Veuillez vous reconnecter pour continuer.",
        "unauthenticated": "Vous devez vous connecter ou vous inscrire pour continuer.",
        "unconfirmed": "Vous devez valider votre compte pour continuer."
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirmer mon email",
          "greeting": "Bienvenue %{recipient} !",
          "instruction": "Vous pouvez confirmer votre email grâce au lien ci-dessous :",
          "subject": "Instructions de confirmation"
        },
        "email_changed": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous informer que votre email a été changé en %{email}.",
          "message_unconfirmed": "Nous vous contactons pour vous informer que votre email est en train d'être changé par %{email}.",
          "subject": "Email modifié"
        },
        "password_change": {
          "greeting": "Bonjour %{recipient} !",
          "message": "Nous vous contactons pour vous notifier que votre mot de passe a été modifié.",
          "subject": "Mot de passe modifié"
        },
        "reset_password_instructions": {
          "action": "Changer mon mot de passe",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Quelqu'un a demandé un lien pour changer votre mot de passe, le voici :",
          "instruction_2": "Si vous n'avez pas émis cette demande, merci d'ignorer cet email.",
          "instruction_3": "Votre mot de passe ne changera pas tant que vous n'aurez pas cliqué sur ce lien et renseigné un nouveau mot de passe.",
          "subject": "Instructions pour changer le mot de passe"
        },
        "unlock_instructions": {
          "action": "Débloquer mon compte",
          "greeting": "Bonjour %{recipient} !",
          "instruction": "Suivez ce lien pour débloquer votre compte :",
          "message": "Votre compte a été bloqué suite à un nombre d'essais de connexions manquées trop important.",
          "subject": "Instructions pour déverrouiller le compte"
        }
      },
      "omniauth_callbacks": {
        "failure": "Nous n'avons pas pu vous authentifier via %{kind} : '%{reason}'.",
        "success": "Authentifié avec succès via %{kind}."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Modifier mon mot de passe",
          "confirm_new_password": "Confirmer le nouveau mot de passe",
          "minimum_password_length": "Minimum de %{count} caractères",
          "new_password": "Nouveau mot de passe",
          "or_continue_with": "ou continuer avec",
          "title": "Modifier mon mot de passe"
        },
        "new": {
          "or_continue_with": "ou continuer avec",
          "send_password_reset_info": "Envoyer les instructions",
          "title": "Mot de passe oublié ?"
        },
        "no_token": "Vous ne pouvez accéder à cette page sans passer par un e-mail de réinitialisation de mot de passe. Si vous êtes passé par un e-mail de ce type, assurez-vous d'utiliser l'URL complète.",
        "send_instructions": "Vous allez recevoir les instructions de réinitialisation du mot de passe dans quelques instants.",
        "send_paranoid_instructions": "Si votre e-mail existe dans notre base de données, vous allez recevoir un lien de réinitialisation par e-mail.",
        "updated": "Votre mot de passe a été édité avec succès, vous êtes maintenant connecté.",
        "updated_not_active": "Votre mot de passe a été changé avec succès."
      },
      "registrations": {
        "destroyed": "Votre compte a été supprimé avec succès. Nous espérons vous revoir bientôt.",
        "new": {
          "already_a_member": "Déjà inscrit ?",
          "minimum_password_length": "Minimum de %{count} caractères",
          "or_continue_with": "ou continuer avec",
          "sign_up": "Inscription",
          "signin_to_your_account": "Connectez vous.",
          "title": "Inscription en tant que %{as}"
        },
        "signed_up": "Bienvenue, vous êtes connecté.",
        "signed_up_but_inactive": "Vous êtes bien enregistré. Vous ne pouvez cependant pas vous connecter car votre compte n'est pas encore activé.",
        "signed_up_but_locked": "Vous êtes bien enregistré. Vous ne pouvez cependant pas vous connecter car votre compte est verrouillé.",
        "signed_up_but_unconfirmed": "Un message contenant un lien de confirmation a été envoyé à votre adresse email. Ouvrez ce lien pour activer votre compte.",
        "update_needs_confirmation": "Votre compte a bien été mis à jour mais nous devons vérifier votre nouvelle adresse email. Merci de vérifier vos emails et de cliquer sur le lien de confirmation pour finaliser la validation de votre nouvelle adresse.",
        "updated": "Votre compte a été modifié avec succès.",
        "updated_but_not_signed_in": "Votre compte a été modifié avec succès. Merci de vous reconnecter."
      },
      "sessions": {
        "already_signed_out": "Déconnecté.",
        "new": {
          "not_a_member": "Pas encore inscrit ?",
          "or": "ou",
          "or_continue_with": "ou continuer avec",
          "remember_me": "Rester connecté",
          "sign_in": "Connexion",
          "signup_as_installed": "Inscrivez-vous comme installé",
          "signup_as_substitute": "Inscrivez-vous comme remplaçant",
          "title": "Connexion"
        },
        "signed_in": "Connecté.",
        "signed_out": "Déconnecté."
      },
      "shared": {
        "links": {
          "didnt_receive_confirm_info": "Informations de confirmation non reçues ?",
          "didnt_receive_unlock_info": "Informations de déverrouillage non reçues ?",
          "forgot_password": "Mot de passe oublié ?",
          "signin": "Connexion",
          "signup": "Inscription"
        }
      },
      "unlocks": {
        "new": {
          "or_continue_with": "ou continuer avec",
          "resend_unlock_instructions": "Envoyer les instructions",
          "title": "Compte verrouillé ?"
        },
        "send_instructions": "Vous allez recevoir les instructions nécessaires au déverrouillage de votre compte dans quelques instants.",
        "send_paranoid_instructions": "Si votre compte existe, vous allez bientôt recevoir un email contenant les instructions pour le déverrouiller.",
        "unlocked": "Votre compte a été déverrouillé avec succès, vous êtes maintenant connecté."
      }
    },
    "doctor": "Médecin",
    "edit": "Modifier",
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "doit être accepté(e)",
        "already_confirmed": "a déjà été validé(e), veuillez essayer de vous connecter",
        "aspect_ratio_is_not": "doit avoir un rapport hauteur / largeur de %{aspect_ratio}",
        "aspect_ratio_not_landscape": "doit être une image en format paysage",
        "aspect_ratio_not_portrait": "doit être une image en format portrait",
        "aspect_ratio_not_square": "doit être une image en format carrée",
        "aspect_ratio_unknown": "a un rapport d'aspect inconnu",
        "blank": "doit être rempli(e)",
        "confirmation": "ne concorde pas avec %{attribute}",
        "confirmation_period_expired": "à confirmer dans les %{period}, merci de faire une nouvelle demande",
        "content_type_invalid": "a un type de contenu non valide",
        "dimension_height_equal_to": "la hauteur doit être égale à %{length} pixels",
        "dimension_height_greater_than_or_equal_to": "la hauteur doit être supérieure ou égale à %{length} pixels",
        "dimension_height_inclusion": "la hauteur n'est pas comprise entre %{min} et %{max} pixels",
        "dimension_height_less_than_or_equal_to": "la hauteur doit être inférieure ou égale à %{length} pixels",
        "dimension_max_inclusion": "doit être inférieur ou égal à %{width} x %{height} pixels",
        "dimension_min_inclusion": "doit être supérieur ou égal à %{width} x %{height} pixels",
        "dimension_width_equal_to": "la largeur doit être égale à %{length} pixels",
        "dimension_width_greater_than_or_equal_to": "la largeur doit être supérieure ou égale à %{length} pixels",
        "dimension_width_inclusion": "la largeur n'est pas comprise entre %{min} et %{max} pixels",
        "dimension_width_less_than_or_equal_to": "la largeur doit être inférieure ou égale à %{length} pixels",
        "empty": "doit être rempli(e)",
        "equal_to": "doit être égal à %{count}",
        "even": "doit être pair",
        "exclusion": "n'est pas disponible",
        "expired": "a expiré, merci d'en faire une nouvelle demande",
        "file_size_not_between": "la taille du fichier doit être comprise entre %{min_size} et %{max_size} (la taille actuelle est %{file_size})",
        "file_size_not_greater_than": "la taille du fichier doit être supérieure à %{min_size} (la taille actuelle est %{file_size})",
        "file_size_not_greater_than_or_equal_to": "la taille du fichier doit être supérieure ou égale à %{min_size} (la taille actuelle est %{file_size})",
        "file_size_not_less_than": "la taille du fichier doit être inférieure à %{max_size} (la taille actuelle est %{file_size})",
        "file_size_not_less_than_or_equal_to": "la taille du fichier doit être inférieure ou égale à %{max_size} (la taille actuelle est %{file_size})",
        "greater_than": "doit être supérieur à %{count}",
        "greater_than_or_equal_to": "doit être supérieur ou égal à %{count}",
        "image_metadata_missing": "n'est pas une image valide",
        "image_not_processable": "n'est pas une image valide",
        "in": "doit être dans l'intervalle %{count}",
        "inclusion": "n'est pas inclus(e) dans la liste",
        "invalid": "n'est pas valide",
        "less_than": "doit être inférieur à %{count}",
        "less_than_or_equal_to": "doit être inférieur ou égal à %{count}",
        "limit_out_of_range": "le nombre total est hors limites",
        "model_invalid": "Validation échouée : %{errors}",
        "not_a_number": "n'est pas un nombre",
        "not_an_integer": "doit être un nombre entier",
        "not_found": "n'a pas été trouvé(e)",
        "not_locked": "n'était pas verrouillé(e)",
        "not_saved": {
          "one": "1 erreur a empêché ce(tte) %{resource} d'être sauvegardé(e) :",
          "other": "%{count} erreurs ont empêché ce(tte) %{resource} d'être sauvegardé(e) :"
        },
        "odd": "doit être impair",
        "other_than": "doit être différent de %{count}",
        "present": "doit être vide",
        "required": "doit exister",
        "taken": "est déjà utilisé(e)",
        "too_long": {
          "one": "est trop long (pas plus d'un caractère)",
          "other": "est trop long (pas plus de %{count} caractères)"
        },
        "too_short": {
          "one": "est trop court (au moins un caractère)",
          "other": "est trop court (au moins %{count} caractères)"
        },
        "total_file_size_not_between": "la taille totale des fichiers doit être comprise entre %{min_size} et %{max_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_greater_than": "la taille totale des fichiers doit être supérieure à %{min_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_greater_than_or_equal_to": "la taille totale des fichiers doit être supérieure ou égale à %{min_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_less_than": "la taille totale des fichiers doit être inférieure à %{max_size} (la taille actuelle est %{total_file_size})",
        "total_file_size_not_less_than_or_equal_to": "la taille totale des fichiers doit être inférieure ou égale à %{max_size} (la taille actuelle est %{total_file_size})",
        "url": "n'est pas une URL valide",
        "wrong_length": {
          "one": "ne fait pas la bonne longueur (doit comporter un seul caractère)",
          "other": "ne fait pas la bonne longueur (doit comporter %{count} caractères)"
        }
      },
      "template": {
        "body": "Veuillez vérifier les champs suivants : ",
        "header": {
          "one": "Impossible d'enregistrer ce(tte) %{model} : %{count} erreur",
          "other": "Impossible d'enregistrer ce(tte) %{model} : %{count} erreurs"
        }
      }
    },
    "helpers": {
      "select": {
        "prompt": "Veuillez sélectionner"
      },
      "submit": {
        "create": "Créer un(e) %{model}",
        "submit": "Enregistrer ce(tte) %{model}",
        "update": "Modifier ce(tte) %{model}"
      }
    },
    "home": {
      "index": {
        "go_to_admin_dashboard": "Accéder à mon compte admin",
        "go_to_installed_dashboard": "Accéder à mon compte installé",
        "go_to_substitute_dashboard": "Accéder à mon compte remplaçant",
        "installed_doctor": "Médecin installé à la recherche d'un remplaçant",
        "sign_in": "Connexion",
        "sign_up_as_installed": "S'inscrire comme installé",
        "sign_up_as_substitute": "S'inscrire comme remplaçant",
        "substitute_doctor": "Médecin remplaçant à la recherche d'un cabinet"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "À": "A",
          "Â": "A",
          "Æ": "Ae",
          "Ç": "C",
          "È": "E",
          "É": "E",
          "Ê": "E",
          "Ë": "E",
          "Î": "I",
          "Ï": "I",
          "Ô": "O",
          "Ù": "U",
          "Û": "U",
          "Ü": "U",
          "à": "a",
          "â": "a",
          "æ": "ae",
          "ç": "c",
          "è": "e",
          "é": "e",
          "ê": "e",
          "ë": "e",
          "î": "i",
          "ï": "i",
          "ô": "o",
          "ù": "u",
          "û": "u",
          "ü": "u",
          "ÿ": "y",
          "Œ": "Oe",
          "œ": "oe",
          "Ÿ": "Y"
        }
      }
    },
    "installed": {
      "conversations": {
        "conversation": {
          "last_message": "Dernier message il y a",
          "show_conversation": "Voir",
          "unread_messages": {
            "one": "Un message non lu",
            "other": "%{count} messages non lu",
            "zero": "Aucun message non lu"
          }
        },
        "index": {
          "my_conversations": "Mes conversations"
        },
        "show": {
          "about_replacement": "A propos de ce remplacement",
          "conversation": "Conversation avec le Dr %{substitute_user}",
          "conversations": "Conversations",
          "no_messages": "Aucun message",
          "show_replacement": "Voir le remplacement"
        }
      },
      "dashboard": {
        "index": {
          "create_office": "Créer mon cabinet",
          "create_replacement": "Créer mon remplacement",
          "create_your_first_office": "Créez votre premier cabinet afin de pouvoir publier votre recherche de remplaçants.",
          "create_your_first_replacement": "Créez votre première demande de remplacement.",
          "dashboard": "Accueil médecin remplacé",
          "next_step_office": "🏥 Prochaine étape",
          "next_step_replacement": "📅 Prochaine étape",
          "welcome": "Bienvenue %{user} ! 👋"
        }
      },
      "messages": {
        "form": {
          "write_your_message": "Rédigez votre message"
        },
        "message": {
          "read": "Lu"
        }
      },
      "offices": {
        "edit": {
          "edit_office": "Modifier le cabinet %{office}"
        },
        "form": {
          "comments": "Précisions",
          "equipments": "Équipements",
          "information": "Informations",
          "save_office": "Sauvegarder le cabinet",
          "settings": "Paramètres"
        },
        "index": {
          "my_offices": "Mes cabinets",
          "new_office": "Nouveau cabinet"
        },
        "new": {
          "new_office": "Nouveau cabinet"
        },
        "office": {
          "show_office": "Voir le cabinet",
          "updated": "Dernière mise à jour il y a"
        },
        "show": {
          "add_schedule": "Nouveau planning",
          "address": "Adresse",
          "days": "jours",
          "equipments": "Équipements",
          "information": "Informations",
          "no_pictures": "Aucune image",
          "office": "Cabinet %{office}",
          "pictures": "Photos",
          "schedules": "Plannings"
        }
      },
      "profiles": {
        "edit": {
          "edit_profile": "Modifier mon profil"
        },
        "form": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "save_profile": "Sauvegarder mon profil"
        },
        "show": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "profile": "Mon profil"
        }
      },
      "replacements": {
        "edit": {
          "edit_replacement": "Modifier le remplacement %{replacement}"
        },
        "form": {
          "date_range": "Plage de remplacement",
          "information": "Informations",
          "save_replacement": "Sauvegarder le remplacement",
          "select_schedules": "Sélectionner un ou des plannings"
        },
        "index": {
          "my_replacements": "Mes remplacements",
          "new_replacement": "Nouveau remplacement"
        },
        "new": {
          "new_replacement_for_office": "Nouveau remplacement pour %{office}"
        },
        "prepare_form": {
          "continue": "Continuer",
          "office": "Cabinet"
        },
        "prepare_new": {
          "new_replacement": "Nouveau remplacement"
        },
        "show": {
          "cancel_replacement": "Annuler le remplacement",
          "cancel_replacement_confirmation": "Voulez-vous vraiment annuler le remplacement ?",
          "conversation": "Conversation",
          "delete_confirmation": "Voulez-vous vraiment supprimer ce remplacement ?",
          "found_someone": "Vous avez trouvé un remplaçant ?",
          "information": "Informations",
          "mark_as_provided": "Marquer comme pourvu",
          "replacement": "Remplacement %{replacement}",
          "replacement_provided": {
            "female": "Remplaçante trouvée ! 🎉",
            "male": "Remplaçant trouvé ! 🎉"
          },
          "select_substitute": "Sélectionnez le médecin remplaçant"
        }
      },
      "schedules": {
        "form": {
          "information": "Informations",
          "save_schedule": "Sauvegarder le planning",
          "schedule": "Planning"
        },
        "new": {
          "new_schedule_for_office": "Nouveau planning pour le cabinet %{office}"
        },
        "show": {
          "create_slot": "Nouveau créneau",
          "delete": "Supprimer",
          "delete_or_edit_slot": "Modifier ou supprimer un créneau.",
          "new_schedule_slot_name": "Nom du créneau",
          "save": "Sauvegarder",
          "schedule": "Planning",
          "schedule_for_office": "Planning %{schedule} pour le cabinet %{office}",
          "slot_title": "Nom du créneau",
          "slot_title_cannot_be_empty": "Le nom du créneau est requis.",
          "slot_type": "Type de créneau"
        }
      }
    },
    "layouts": {
      "admin": {
        "dashboard": "Accueil",
        "notifications": "Notifications",
        "sign_out": "Déconnexion"
      },
      "installed": {
        "conversations": "Conversations",
        "dashboard": "Accueil",
        "notifications": "Notifications",
        "offices": "Cabinets",
        "replacements": "Remplacements",
        "sign_out": "Déconnexion"
      },
      "substitute": {
        "conversations": "Conversations",
        "dashboard": "Accueil",
        "notifications": "Notifications",
        "replacements": "Remplacements",
        "sign_out": "Déconnexion"
      }
    },
    "missing": "Manquant(e)",
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "round_mode": "default",
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "milliard",
            "million": "million",
            "quadrillion": "million de milliards",
            "thousand": "millier",
            "trillion": "billion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "octet",
              "other": "octets"
            },
            "eb": "Eo",
            "gb": "Go",
            "kb": "ko",
            "mb": "Mo",
            "pb": "Po",
            "tb": "To"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "open": "Ouvrir",
    "replacement_mailer": {
      "new_search_results": {
        "address": "Autour de %{address}",
        "body": "De nouveaux remplacements correspondent à votre recherche créée le %{date}.",
        "ends_on": "Jusqu'au %{date}",
        "equipments": "Équipements disponibles obligatoirement : %{equipments}",
        "greeting": "Bonjour %{user},",
        "radius": "Dans un rayon de %{radius} km",
        "search_params": "Pour rappel, vos paramètres de recherche sont :",
        "starts_on": "Début à partir du %{date}",
        "stop_notifications": "Pour ne plus recevoir de notifications pour cette recherche, cliquez sur le lien ci-dessous.",
        "subject": "[Alorempla] Nouveaux remplacements correspondant à votre recherche"
      }
    },
    "required": "requis",
    "shared": {
      "flashes": {
        "a_problem_occurred": "Un problème est survenu  👎",
        "attention": "Attention  👀",
        "well_done": "Bien joué  👍"
      },
      "update_email_panel": {
        "change_email_address_from_psc": "Vous vous êtes inscrit(e) avec Pro Santé Connect. Par conséquent, nous ne disposons pas de votre adresse e-mail personnelle pour vous envoyer des notifications.",
        "confirm_new_email_address": "Confirmez votre nouvelle adresse e-mail en cliquant sur le lien que nous vous avons envoyé.",
        "email": "Adresse email",
        "email_address_update_pending": "📨 Mise à jour de l'adresse e-mail en cours",
        "save": "Enregistrer",
        "update_email_address": "Mettez à jour votre adresse e-mail !"
      }
    },
    "show": "Afficher",
    "substitute": {
      "conversations": {
        "conversation": {
          "last_message": "Dernier message il y a",
          "show_conversation": "Voir",
          "unread_messages": {
            "one": "Un message non lu",
            "other": "%{count} messages non lu",
            "zero": "Aucun message non lu"
          }
        },
        "index": {
          "my_conversations": "Mes conversations"
        },
        "show": {
          "about_replacement": "A propos de ce remplacement",
          "conversation": "Conversation avec le Dr %{installed_user}",
          "conversations": "Conversations",
          "no_messages": "Aucun message",
          "show_replacement": "Voir le remplacement"
        }
      },
      "dashboard": {
        "index": {
          "dashboard": "Accueil médecin remplaçant",
          "find_your_first_replacement": "Trouvez votre premièr remplacement.",
          "next_step_replacement": "📅 Prochaine étape",
          "search_replacement": "Rechercher",
          "welcome": "Bienvenue %{user} ! 👋"
        }
      },
      "messages": {
        "form": {
          "write_your_message": "Rédigez votre message"
        },
        "message": {
          "read": "Lu"
        }
      },
      "profiles": {
        "edit": {
          "edit_profile": "Modifier mon profil"
        },
        "form": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "save_profile": "Sauvegarder mon profil"
        },
        "show": {
          "information": "Informations",
          "locum_tenens_license": "License de remplacement",
          "professional_card": "Carte professionnelle",
          "profile": "Mon profil"
        }
      },
      "replacements": {
        "index": {
          "my_replacements": "Mes remplacements",
          "search_replacement": "Chercher un remplacement"
        },
        "search": {
          "address": "Adresse",
          "delete_notifications_for_new_results": "Supprimer les notifications",
          "ends_on": "Fin avant le...",
          "equipments": "Équipements du cabinet",
          "filters": "Filtres",
          "get_notified_of_new_results": "Recevoir les nouveaux résultats",
          "in_radius": "Dans un rayon de (km)",
          "no_replacements_for_your_criterias": "Aucun remplacement ne correspond à vos critères",
          "radius": "Rayon (km)",
          "search": "Rechercher",
          "search_around": "Chercher autour de",
          "search_around_this_address": "Chercher autour de cette adresse",
          "search_replacement": "Chercher un remplacement",
          "starts_on": "Début aprés le..."
        },
        "search_replacement": {
          "contact": "Contacter",
          "dates": "Dates",
          "details": "Détails"
        },
        "show": {
          "cancel_replacement": "Annuler mon remplacement",
          "cancel_replacement_confirmation": "Voulez-vous vraiment annuler votre remplacement ? Le médecin en sera notifié.",
          "contact": "Contacter",
          "conversation": "Conversation",
          "equipments": "Équipements",
          "information": "Informations",
          "no_pictures": "Aucune image",
          "office": "Cabinet",
          "office_pictures": "Photos du cabinet",
          "replacement": "Remplacement %{replacement}",
          "schedules": "Plannings",
          "you_are_the_substitute_for_this_replacement": {
            "female": "Vous êtes la médecin remplaçante ! 🎉",
            "male": "Vous êtes le médecin remplaçant ! 🎉"
          }
        }
      }
    },
    "support": {
      "array": {
        "last_word_connector": " et ",
        "two_words_connector": " et ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%d %B %Y %Hh %Mmin %Ss",
        "long": "%A %d %B %Y %Hh%M",
        "short": "%d %b %Hh%M",
        "time": "%H:%M"
      },
      "pm": "pm"
    }
  }
});
i18n.locale = window.Alorempla.locale;
export default i18n;
